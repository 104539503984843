
  export default {
    data() {
      return {

        form : {
          id : '',
          uraian : '',
          startDatang : '',
          finishDatang : '',
          startPulang : '',
          finishPulang : '',
          keterangan : '',
        },
      
        list_data : [],

        page_first: 1,
        page_last: 0,
        cari_value: "",
        cek_load_data : true,


        mdl_add: false,
        mdl_edit: false,
        mdl_hapus : false,
        btn_add: false,
      }
    },
    methods: {


      getView : function(){
        this.$store.commit("shoWLoading");
        fetch(this.$store.state.url.URL_MasterWaktuAbsen + "view", {
            method: "POST",
            headers: {
            "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
            },
            body: JSON.stringify({
                data_ke: this.page_first,
                cari_value: this.cari_value
            })
        })
            .then(res => res.json())
            .then(res_data => {
                this.list_data = res_data.data;
                this.page_last = res_data.jml_data;
                this.$store.commit("hideLoading");
                console.log(res_data);
        });
      },


      addData : function(number) {
        fetch(this.$store.state.url.URL_MasterWaktuAbsen + "Add", {
            method: "POST",
            headers: {
              "content-type": "application/json",
              authorization: "kikensbatara " + localStorage.token
            },
            body: JSON.stringify(this.form)
        }).then(res_data => {
            this.Notify('Sukses Menambah Data', 'primary', 'check_circle_outline');
            this.getView();
        });
      },


      editData : function(){
        fetch(this.$store.state.url.URL_MasterWaktuAbsen + "editData", {
            method: "POST",
            headers: {
              "content-type": "application/json",
              authorization: "kikensbatara " + localStorage.token
            },
            body: JSON.stringify(this.form)
        }).then(res_data => {
            this.Notify('Sukses Merubah Data', 'warning', 'check_circle_outline');
            this.getView();
        });
      },

      removeData : function(E){
        fetch(this.$store.state.url.URL_MasterWaktuAbsen + "removeData", {
            method: "POST",
            headers: {
              "content-type": "application/json",
              authorization: "kikensbatara " + localStorage.token
            },
            body: JSON.stringify({id : this.form.id})
        }).then(res_data => {
            this.Notify('Sukses Menghapus Data', 'negative', 'check_circle_outline');
            this.getView();
        });

      },
      selectData : function(data){
          this.form.id = data.id;
          this.form.uraian = data.uraian;
          this.form.startDatang = data.startDatang;
          this.form.finishDatang = data.finishDatang;
          this.form.startPulang = data.startPulang;
          this.form.finishPulang = data.finishPulang;
          this.form.keterangan = data.keterangan;
      },

      // ====================================== PAGINATE ====================================
          Notify : function(message, positive, icon){
            this.$q.notify({
              message: message,
              color: positive,
              icon: icon,
              position : 'top',
              timeout: 500,
            })
          },
          btn_prev : function(){
              this.cek_load_data = true;
              if(this.page_first>1){
                  this.page_first--
              }else{
                  this.page_first = 1;
              }
              this.getView();
          },

          btn_next : function(){
              if(this.page_first >= this.page_last){
                  this.page_first == this.page_last
              }else{
                  this.page_first++;
              }
              this.getView();
          },

          cari_data : function(){
              this.page_first = 1;
              this.getView();
          },


      // ====================================== PAGINATE ====================================







    },

    mounted () {
      this.getView();
    },
  }
